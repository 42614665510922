import $ from "jquery";

/**
 * Carica le option di una select
 * @param selector
 * @param selectValues
 */
window.loadSelect = function(selector, selectValues) {
    $(selector)
        .find('option')
        .remove()
        .end();
    $.each(selectValues, function (key, value) {
        $(selector)
            .append($("<option></option>")
                .attr("value", key)
                .text(value));
    });
}
