import $ from "jquery";

$(function () {
    $(".editable-prov-city").each(function() {
        var select = $(this);
        var data = $(this).data();

        freeFieldsVisibility(select, data);
        select.change(function(){
            freeFieldsVisibility(select, data);
        });

    });

    function freeFieldsVisibility(select, data) {
        var provSlct = $("#" + data.provField);
        var citySlct = $("#" + data.cityField);
        var provFree = $("#" + data.provField + "-free");
        var cityFree = $("#" + data.cityField + "-free");
        switch(select.val()) {
            case "IT":
                disableField(provFree);
                disableField(cityFree);
                enableField(provSlct);
                enableField(citySlct);
                break;
            default:
                enableField(provFree);
                enableField(cityFree);
                disableField(provSlct);
                disableField(citySlct);
                break;
        }
    }

    function disableField(f) {
        f.hide();
        f.attr('disabled');
    }

    function enableField(f) {
        f.show();
        f.removeAttr('disabled');
    }
});