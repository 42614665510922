/**
 * Fonte: https://gist.github.com/fakiolinho/5438830
 * @param Email
 * @returns {boolean}
 */
window.validateEmail = function(Email) {
    var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return $.trim(Email).match(pattern) ? true : false;
}


/**
 * Effettua il calcolo dei giorni tra le due date escludendo la domenica.
 * @param {Moment} dateA Data di inizio
 * @param {Moment} dateB Data di fine
 */
window.calc_business_days = function (dateA, dateB) {

    // Otteniamo le varie date nel formato che ci serve
    var mom_start = moment(dateA);
    var mom_end = moment(dateB);

    // Capiamo quale sia la data di inizio
    if(mom_start.isAfter(mom_end))
    {
        mom_start = moment(dateB);
        mom_end = moment(dateA);
    }

    // Iniziamo a contare finchè i giorni sono diversi 
    var d = mom_start.isoWeekday() == 7 ? 0 : 1;;   // Conteggiamo SEMPRE un giorno di partenza se non è domenica
    while ( mom_end.diff(mom_start,'days') > 0)      
    {
        // Andiamo avanti con lo start
        mom_start.add(1, 'd');
        
        // Conteggiamo un giorno
        d++;

        // Saltiamo la domenica
        if(mom_start.isoWeekday() == 7)
            d--;
    }

    // Ritorna il conteggio totale
    return d;
}
