import $ from 'jquery';

window.$ = window.jQuery = $;

import "./jquery.maskedinput";
import "./frontend_utility";
import "./loadSelect";
import "./editableProvCity";

window.Vue = require('vue');

import GanttElastic from 'gantt-elastic';
import Header from 'gantt-elastic-header';
window.GanttElastic = GanttElastic;
window.GanttHeader = Header;

import dayjs from 'dayjs'
window.dayjs = dayjs;